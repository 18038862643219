import React, { Component } from 'react';
import { Link } from 'gatsby';

import Logo from '../../utils/img-static-query';
import Styles from './header.module.scss';

class Header extends Component {
  constructor(props) {
    super(props); 

    this.state = { 
      isOpen: false,
      delay: false,
    };

    this.menuHandler = this.menuHandler.bind( this );
    this.menuKeyAccess = this.menuKeyAccess.bind( this );
    this.documentController = this.documentController.bind( this );
  }
  
  componentDidMount() {
    // handle keypress on the header
    let headerElement = document.querySelector('header');

    headerElement.addEventListener('keydown', (e) => this.menuKeyAccess(e));

    // handle focus on the document
    document.body.addEventListener('mousedown', () => 
      document.body.classList.add('using-mouse')
    );
    document.body.addEventListener('keydown', () => 
      document.body.classList.remove('using-mouse')
    );
  }

  componentWillUnmount() {
    
    // handle keypress on the header
    let headerElement = document.querySelector('header');
    
    headerElement.addEventListener('keydown', (e) => this.menuKeyAccess(e));
    
    if (document.body.classList.contains(Styles.bodyActive)) {
      document.body.classList.remove( Styles.bodyActive );
    }

    document.body.removeEventListener('mousedown', () => 
      document.body.classList.add('using-mouse')
    );
    document.body.removeEventListener('keydown', () => 
      document.body.classList.remove('using-mouse')
    );
  }

  /**
   * Menu Handler
   * Tracks the state of menu
   * Delays on closing
   * @async this.setState()
   * @callback this.documentController()
   */
  menuHandler() {

    if( this.state.delay ) {

      this.setState( ( state ) => ({ isOpen: !state.isOpen }), 
        () => this.documentController() 
      );

      setTimeout(() => {
        this.setState( ( state ) => ({ delay: !state.delay }));
      }, 300);
      
    } else {
      
      this.setState( ( state ) => ({ 
        isOpen: !state.isOpen, delay: !state.delay }), 
        () => this.documentController() 
      );

    }
  }

  /**
   * Controlls document scrolling
   */
  documentController() {

    if( this.state.isOpen ) {
      document.body.classList.add( Styles.bodyActive );
    }
    else {
      document.body.classList.remove( Styles.bodyActive );
    }
  }

  /**
   * Access Controlls for the menu on tab &  escape press
   * @param {Event} e 
   */
  menuKeyAccess(e) {

    let indexList = document.querySelectorAll('header a, header button');
    let listLength = indexList.length - 1;

    // if menu is open
    if( this.state.isOpen ) {

      // if escape is pressed
      if ( e.key === "Escape" ) {
        this.menuHandler();
      }
      
      // if tab is pressed on last element
      if ( e.key === "Tab" && !e.shiftKey && indexList[listLength] === e.target ) {
        e.preventDefault();
        indexList[0].focus();
      }

      // if tab+shift is pressed in the first
      if ( e.key === "Tab" && e.shiftKey && indexList[0] === e.target ) {
        e.preventDefault();
        indexList[listLength].focus();
      }
    }    
  }

  render() {

    return (

      <header 
        className={
          `${Styles.header} ${this.state.delay ? Styles.headerActive : ''}`
        }
      >

        <div className={Styles.headerOuterContainer}>
          <div className={`container ${Styles.headerInnerContainer}`}>
            <div className={Styles.headerLogo}>
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <button 
              onClick={this.menuHandler.bind(this)} 
              aria-label="Main Menu"
              className={
                `${Styles.headerButton} ${this.state.isOpen ? 
                    Styles.headerButtonActive : ''}`
              }
            >
              <div className={Styles.headerButtonTop}></div>
              <div className={Styles.headerButtonMid}></div>
              <div className={Styles.headerButtonBottom}></div>
            </button>
          </div>
        </div>
        
        <div 
          className={
            `${Styles.headerOverlay} ${this.state.delay ? 
              Styles.headerOverlayActive : ''}`
          }
        >
          <ul 
            className={`${Styles.headerMenuList} ${this.state.isOpen ? 
              Styles.headerMenuListActive : ''}`}
          >
            <li className={Styles.headerMenuLine}>
              <Link 
                to="/"
                className={Styles.headerLink}
                activeClassName="link--active"
              >
                Home
              </Link>
            </li>
            <li className={Styles.headerMenuLine}>
              <Link 
                to="/about"
                className={Styles.headerLink}
                activeClassName="link--active"
              >
                About Us
              </Link>
            </li>
            <li className={Styles.headerMenuLine}>
              <Link 
                to="/clients"
                className={Styles.headerLink}
                activeClassName="link--active"
              >
                Clients
              </Link>
            </li>
            <li className={Styles.headerMenuLine}>
              <Link 
                to="/contact"
                className={Styles.headerLink}
                activeClassName="link--active"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

      </header>
    )
  }
};

export default Header;
