import React from 'react';
import { graphql } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import InnerPagesBanner from '../components/inner-pages-banner/inner-pages-banner';
import AboutUsDescription from '../components/about-us-description/about-us-description';
import TeamContainer from '../components/team-container/team-container';

export default ( props ) => {

  const innerBannerData = {
    backgroundImg: 
      <BackgroundImage 
        Tag="section"
        className="inner-pages__background"
        fluid={props.data.background_placeholder.childImageSharp.fluid}
      ></BackgroundImage>,
    mainTitle: 'About Us',
    mainText: 'We are a team of strong creative technologists who have passion for digital.'
  };

  const descriptionData = {
    img: 
      <Img 
        alt="animation" 
        fluid={props.data.animation_placeholder.childImageSharp.fluid} 
      />,
  };

  /**
   * Array of object's data for team component
   * Spread operator creates new object with correct indexation
   */
  const teamData = [

    {
      teamImg: 
        <Img 
          alt="member" 
          fluid={props.data.team_member.childImageSharp.fluid} 
        />,
      name: 'Frederico Silva',
      position: 'Full Stack Developer',
      roles: ['JS', 'HTML', 'React', 'Wordpress']
    },
    {
      teamImg: 
        <Img 
          alt="member" 
          fluid={props.data.team_member.childImageSharp.fluid} 
        />,
      name: 'Frederico Silva',
      position: 'Full Stack Developer',
      roles: ['JS', 'HTML', 'React', 'Wordpress']
    },
    {
      teamImg: 
        <Img 
          alt="member" 
          fluid={props.data.team_member.childImageSharp.fluid} 
        />,
      name: 'Frederico Silva',
      position: 'Full Stack Developer',
      roles: ['JS', 'HTML', 'React', 'Wordpress']
    },
    {
      teamImg: 
        <Img 
          alt="member" 
          fluid={props.data.team_member.childImageSharp.fluid} 
        />,
      name: 'Frederico Silva',
      position: 'Full Stack Developer',
      roles: ['JS', 'HTML', 'React', 'Wordpress']
    },
    {
      teamImg: 
        <Img 
          alt="member" 
          fluid={props.data.team_member.childImageSharp.fluid} 
        />,
      name: 'Frederico Silva',
      position: 'Full Stack Developer',
      roles: ['JS', 'HTML', 'React', 'Wordpress']
    },
    {
      teamImg: 
        <Img 
          alt="member" 
          fluid={props.data.team_member.childImageSharp.fluid} 
        />,
      name: 'Frederico Silva',
      position: 'Full Stack Developer',
      roles: ['JS', 'HTML', 'React', 'Wordpress']
    }
  ];

  return (
    <Layout>
      <SEO 
        title="About" 
        keywords={[`digital`, `agency`, `technology`, `Radium`, `About`]} 
      />
      <InnerPagesBanner {...innerBannerData}/>
      <AboutUsDescription {...descriptionData}/>
      <TeamContainer {...teamData}/>
    </Layout>
  )
};

export const aboutUsQuery = graphql`
  query {
    background_placeholder: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "placeholder1300x800.png" }) {
      ...fluidNormal
    }

    animation_placeholder: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "placeholder600x400.png" }) {
      ...fluidNormal
    }

    team_member: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "avatar.jpeg" }) {
      ...fluidNormal
    }
  }
`;