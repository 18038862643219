import React from 'react';

import Styles from './about-us-description.module.scss';

export default ({ img }) => (
  
  <section className={Styles.descritionSection}>
    <div className="container">
      <div className={Styles.container}>
        <div className={Styles.animatedContainer}>
          <div className={Styles.animatedInnerContainer}>
            {img}
          </div>
        </div>
        <div className={Styles.descritpionContainer}>
          <h2 className={Styles.descriptionTitle}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </h2>
          <p className={Styles.descriptionText}>
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
          </p>
        </div>
      </div>
    </div>
  </section>
);
