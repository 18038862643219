import React from 'react';
import { FaFacebookSquare, FaGooglePlusG, FaInstagram } from 'react-icons/fa';

import Styles from './footer.module.scss'

export default () => (

  <footer>
    <div className="container">
      <p className={Styles.copy}>
        Copyright &copy; Radium / Cookie &amp; Privacy Policy / Legal Notices
      </p>
      <ul className={Styles.socialList}>
        <li className={Styles.socialLine}>
          <FaFacebookSquare />
        </li>
        <li className={Styles.socialLine}>
          <FaGooglePlusG />
        </li>
        <li className={Styles.socialLine}>
          <FaInstagram />
        </li>
      </ul>
    </div>
  </footer>
);
