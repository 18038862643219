import React from 'react';
import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';

const imageQuery = graphql`
  query {
    logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "radium-white.png" }) {
      childImageSharp {
        fixed(width: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default () => (
  <StaticQuery
    query={imageQuery}
    render={data => 
      <Img 
        imgStyle={{ transition: 'opacity .1s' }}
        fixed={data.logo.childImageSharp.fixed} 
      />
    }
  />
)
