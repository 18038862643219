import React from 'react';

import TeamMember from '../team-template/team-template';

import Styles from './team-container.module.scss';

export default ( props ) => (
  
  <section className={Styles.teamSection}>
    <div className={Styles.sectionTitle}>
      <h2>Meet The Team</h2>
    </div>

    <div className="container">
      <div className={Styles.teamContainer}>
        {Object.entries(props).map( ( [key, value] ) => 
            <TeamMember key={key} {...value}/>
        )}
      </div>
    </div>
  </section>
);
