import React from 'react';
import { IoMdCall, IoIosMail } from 'react-icons/io';

import Styles from './get-in-touch.module.scss'

export default () => (

  <section className={Styles.getInTouch}>
    <div className={Styles.getInTouchContainer}>
      <div className={Styles.textContainer}>
        <div className={Styles.textInnerContainer}>
          <h3 className={Styles.title}>Get in Touch</h3>
          <p className={Styles.text}>
            If you have any further questions or 
            just want to say hello, here is our contact details.
          </p>
        </div>
      </div>
      <div className={Styles.contactContainer}>
        <ul className={Styles.contactList}>
          <li className={Styles.phoneLine}>
            <IoMdCall />
            <a
              href="tel:+4402071831585"
              className={Styles.phone}
            >
              +44 (0)20 7183 1585
            </a>
          </li>
          <li className={Styles.emailLine}>
            <IoIosMail />
            <a 
              href="mailto:hello@radiumagency.com"
              className={Styles.email}
            >
              hello@radiumagency.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

